@import "~@styles/variables.scss";
.projectRealEstate {
  border-radius: 15px;
  min-height: 141px;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  @apply flex relative w-full flex-col relative
}

.imgRealEstateProject {
  @apply w-full h-full
}

.containerInfoProject {
  border-radius: 15px;
  transition: all 0.5s ease;
  @apply top-0 w-full h-full flex flex-col absolute justify-end pb-12px
}

.innerContainer {
  transition: all 0.5s ease;
  @apply flex flex-col
}

.infoProject {
  @apply flex flex-col justify-end w-full
}

.viewProject {
  margin-top: 35px;
  background: linear-gradient(to bottom, #AAFA8E, #11C00E);
  @apply hidden w-120px text-12 font-bold font-textBold h-34px rounded-rd16px justify-center items-center text-white cursor-pointer lg:flex
}

.fullAddress {
  @apply text-gray-400 truncate text-11
}

.projectName {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  @apply font-bold font-textBold text-15 text-white uppercase mx-4px lg:mx-12px
}

@media (min-width: 1024px) {
  .projectRealEstate {
    min-height: 210px;
  }
  .innerContainer {
    transform: translateY(60%);
  }
  .infoProject {
    opacity: 0;
  }
}

@media (max-width: 1023px) {
  .containerInfoProject {
    background: linear-gradient(to bottom, #2F163A00, #290429);
  }
  .fullAddress {
    font-size: 7.39px;
  }
  .projectName {
    font-size: 9.4px;
  }
}

.priceKey {
  font-size: 8.73px;
  @apply text-white font-text
}

.priceValue {
  font-size: 10.07px;
  @apply text-white font-text
}

@media (min-width: 1024px) {
  .priceKey {
    @apply text-13 font-textBold font-bold
  }

  .priceValue {
    @apply text-15
  }
}

@media (min-width: 1280px) {
  .projectRealEstate:hover {
    transform: scale(1.05);
    transition-duration: 0.5s;
  }
  .projectRealEstate:hover *.containerInfoProject{
    background: linear-gradient(to bottom, #2F163A00, #290429);
  }
  .projectRealEstate:hover *.innerContainer {
    transform: translateY(0);
    transition-duration: 0.5s;
  }
  .projectRealEstate:hover *.infoProject {
    opacity: 1;
  }
}
