/* purgecss start ignore */

.projectRealEstate{
  border-radius:15px;
  min-height:141px;
  transition:transform .5s ease;
  position:relative;
  position:relative;
  display:flex;
  width:100%;
  flex-direction:column
}

.imgRealEstateProject{
  height:100%;
  width:100%
}

.containerInfoProject{
  border-radius:15px;
  transition:all .5s ease;
  position:absolute;
  top:0px;
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
  justify-content:flex-end;
  padding-bottom:12px
}

.innerContainer{
  transition:all .5s ease;
  display:flex;
  flex-direction:column
}

.infoProject{
  display:flex;
  width:100%;
  flex-direction:column;
  justify-content:flex-end
}

.viewProject{
  margin-top:35px;
  background:linear-gradient(to bottom, #AAFA8E, #11C00E);
  display:none;
  height:34px;
  width:120px;
  cursor:pointer;
  align-items:center;
  justify-content:center;
  border-radius:16px;
  font-family:Mulish, bold;
  font-size:12px;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .viewProject{
    display:flex
  }
}

.fullAddress{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  font-size:11px;
  line-height:13.81px;
  --tw-text-opacity:1;
  color:rgba(156, 163, 175, var(--tw-text-opacity))
}

.projectName{
  overflow:hidden;
  display:-webkit-box;
  text-overflow:ellipsis;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  margin-left:4px;
  margin-right:4px;
  font-family:Mulish, bold;
  font-size:15px;
  font-weight:700;
  text-transform:uppercase;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .projectName{
    margin-left:12px;
    margin-right:12px
  }
}

@media(min-width: 1024px){
  .projectRealEstate{
    min-height:210px
  }

  .innerContainer{
    transform:translateY(60%)
  }

  .infoProject{
    opacity:0
  }
}

@media(max-width: 1023px){
  .containerInfoProject{
    background:linear-gradient(to bottom, #2F163A00, #290429)
  }

  .fullAddress{
    font-size:7.39px
  }

  .projectName{
    font-size:9.4px
  }
}

.priceKey{
  font-size:8.73px;
  font-family:Mulish, sans-serif;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.priceValue{
  font-size:10.07px;
  font-family:Mulish, sans-serif;
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

@media(min-width: 1024px){
  .priceKey{
    font-family:Mulish, bold;
    font-size:13px;
    font-weight:700
  }

  .priceValue{
    font-size:15px
  }
}

@media(min-width: 1280px){
  .projectRealEstate:hover{
    transform:scale(1.05);
    transition-duration:.5s
  }

  .projectRealEstate:hover *.containerInfoProject{
    background:linear-gradient(to bottom, #2F163A00, #290429)
  }

  .projectRealEstate:hover *.innerContainer{
    transform:translateY(0);
    transition-duration:.5s
  }

  .projectRealEstate:hover *.infoProject{
    opacity:1
  }
}

/* purgecss end ignore */